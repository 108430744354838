<template>
    <div
        style="position: relative; width: 100%; min-width: 100px"
        :class="{
            'is-invalid': config.error,
            disabled_cursor: disabled
        }"
    >
        <Multiselect
            :id="id"
            :showPointer="config.showPointer ?? false"
            :showNoResults="config.showNoResults ?? false"
            :selectedLabel="config.selectedLabel ?? ''"
            :deselectLabel="config.deselectLabel ?? ''"
            :placeholder="config.placeholder ?? ''"
            :selectLabel="config.selectLabel ?? ''"
            :showNoOptions="config.showNoOptions ?? false"
            :track-by="config.trackBy ?? 'value'"
            :label="config.label ?? 'label'"
            v-model="valueModel"
            :options="optionsSelect"
            :multiple="config.multiple ?? false"
            :open-direction="config.openDirection ?? 'bottom'"
            :disabled="disabled"
            :allow-empty="config.multiple ?? false"
            :taggable="config.taggable ?? false"
            @select="handleSelected"
            @tag="handleAddTag"
            @open="handleOpen"
            @close="handleClose"
        ></Multiselect>
        <i
            v-if="valueModel && !config.multiple && !config.isRemove"
            @click="handleRemove"
            style="cursor: pointer; position: absolute; right: 12px; top: 11px; background: #fff; z-index: 9"
            class="fa fa-window-close"
        ></i>
        <div v-if="config.loading ?? false" class="loader__select">
            <span class="loader"></span>
        </div>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { masterMethods } from '@/state/helpers';
export default {
    name: 'MultiSelectCommon',
    components: {
        Multiselect
    },
    props: {
        id: {
            type: String,
            default: 'multi-select'
        },
        value: {
            type: [Object, String, Number, Array],
            default: () => ({})
        },
        options: {
            type: Array,
            default: () => []
        },
        config: {
            type: Object,
            required: true,
            default() {
                return {
                    action: '',
                    openDirection: 'bottom',
                    isRemove: false,
                    showPointer: false,
                    showNoResults: false,
                    selectedLabel: '',
                    deselectLabel: '',
                    placeholder: '',
                    selectLabel: '',
                    showNoOptions: false,
                    multiple: false,
                    taggable: false,
                    trackBy: 'value',
                    label: 'label',
                    error: false,
                    loading: false
                };
            }
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            optionsSelect: [],
            valueModel: null || [],
            generatedNumbersDefault: 1
        };
    },
    watch: {
        '$props.value': {
            handler(valReceived) {
                if (!this.config.multiple) {
                    this.valueModel = valReceived;
                } else {
                    this.valueModel = valReceived || [];
                }
            },
            immediate: true
        },
        '$props.options': {
            handler(newOptions) {
                this.optionsSelect = newOptions;
            },
            immediate: true
        },
        valueModel: {
            handler(valSelected) {
                let config = this.config;
                config.error = false;
                this.$emit('update:config', config);
                this.$emit('update:value', valSelected);
            },
            immediate: true
        },
        optionsSelect: {
            handler(newOptions) {
                this.$emit('update:options', newOptions);
            }
        }
    },
    methods: {
        ...masterMethods,

        handleOpen(id) {
            this.$emit('open', id);
        },
        handleClose(value, id) {
            this.$emit('update:value', this.valueModel);
            this.$emit('close', { value, id });
        },

        handleSelected(selectedOption, id) {
            if (!this.config.multiple) {
                this.valueModel = selectedOption;
            }
            this.$emit('update:value', this.valueModel);
            this.$emit('select', selectedOption, id);
        },

        handleAddTag(newOption) {
            if (this.optionsSelect.length > 0) {
                this.generatedNumbersDefault = this.optionsSelect[this.optionsSelect.length - 1].id + 1;
            }
            let newTag = {
                id: this.generatedNumbersDefault,
                name: newOption
            };
            this.optionsSelect.push(newTag);
            this.$emit('addTag', newTag);
        },

        handleRemove(evt) {
            if (this.$props.disabled) return;
            evt.preventDefault();
            this.valueModel = null;
            this.$emit('remove');
        },

        getListMasterCampaign() {
            this.listMaster('all/campaigns').then((data) => {
                this.optionsSelect = data;
            });
        },

        getListDataAciton() {
            if (!this.config.action) return;
            switch (this.config.action) {
                case 'campaigns_setting_phone':
                    this.getListMasterCampaign();
                    break;
                default:
                    console.log('error');
                    break;
            }
        }
    },
    mounted() {
        this.getListDataAciton();
    }
};
</script>

<style lang="scss" scoped>
.loader__select {
    position: absolute;
    top: 6px;
    right: 30px;
    z-index: 999;
    display: flex;
    align-items: center;
}
.loader {
    width: 25px;
    height: 25px;
    border: 3px solid #ffffff;
    border-bottom-color: #c1c1c1;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite;
}
.loader::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid #fff;
    animation: prixClipFix 2s linear infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes prixClipFix {
    0% {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    }
    25% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
    }
    50% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
    }
    75% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
    }
    100% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
    }
}
</style>

<style lang="scss">
.disabled_cursor {
    .multiselect.multiselect--disabled {
        opacity: 0.7;
        .multiselect__tags {
            color: #2c353d !important;
        }
    }
}
</style>
